html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  --table-accent-color: rgb(3, 169, 244, 1);
  --table-accent-color-opacity: rgb(3, 169, 244, 0.05);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dx-datagrid .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table .dx-row:not(.dx-header-row) > td{
  padding-top: 1px;
  padding-bottom: 1px;
  height: 50px;
}

.dx-datagrid .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  font-size: 14px;
  font-weight: 700;
  line-height: 136.8%;
  color: #2c2a27;
  text-align: left;
}

.dx-datagrid .dx-header-row .dx-checkbox-checked .dx-checkbox-icon::before {
  content: "+";
  top: 30%;
}

.dx-datagrid .dx-datagrid-export-button .dx-icon-export-excel-button::before {
  content: url("./icons/ExcelIcon.svg");
}

/* Purchase Order Flow V2 Datagrid Custom Styles */
.purchase-orders-data-grid .dx-checkbox-checked .dx-checkbox-icon {
  background-color: #8eab79 
}

.purchase-orders-data-grid .dx-checkbox-indeterminate .dx-checkbox-icon {
  background-color: #8eab79 
}

.purchase-orders-data-grid .dx-checkbox-icon {
  border: 1px solid #d5d3d0;
  border-radius: 4px;
}

.financing-requests-data-grid .dx-checkbox-checked .dx-checkbox-icon {
  background-color: #769362 
}

.financing-requests-data-grid .dx-checkbox-indeterminate .dx-checkbox-icon {
  background-color: #769362 
}

.financing-requests-data-grid .dx-checkbox-icon {
  border: 1px solid #d5d3d0;
  border-radius: 4px;
}

.active-loans-data-grid .dx-checkbox-checked .dx-checkbox-icon {
  background-color: #769362 
}

.active-loans-data-grid .dx-checkbox-indeterminate .dx-checkbox-icon {
  background-color: #769362 
}

.active-loans-data-grid .dx-checkbox-icon {
  border: 1px solid #d5d3d0;
  border-radius: 4px;
}

.repayments-loans-data-grid-selected .dx-checkbox-icon {
  content: url("./components/Shared/Layout/Icons/DataGridXIcon.svg");
  border: none
}

.repayments-loans-data-grid-not-selected .dx-checkbox-icon {
  content: url("./components/Shared/Layout/Icons/DataGridPlusIcon.svg");
  border: none
}

#root {
  height: 100%;
}
